import { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actions } from "../../redux/loading";
import { INITIAL_LOADING_TIME } from "../../utils/constants";
import { delayMs } from "../../utils/utils";
import { GridItem, WorkInfo, WorkName, WorkArtist, WorkType, Video, Image, NewIconContainer, Color } from "./styles";
import { WorkThumbProps } from "./types";
import { ReactComponent as NewIcon } from "../../assets/images/icono_new.svg";

const WorkThumb = ({ work }: WorkThumbProps) => {
  const itemRef = useRef<HTMLDivElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [showVideo, setShowVideo] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const play = () => {
    setShowVideo(true);
    if (videoRef.current) videoRef.current.play();
  };

  const pause = () => {
    setShowVideo(false);
    if (videoRef.current) videoRef.current.pause();
  };

  const goToWork = async () => {
    dispatch(actions.setLoading(true));
    await delayMs(INITIAL_LOADING_TIME + 100);
    navigate(`/work/${work.tipo}/${work.path_url}`);
  };

  const renderThumb = useCallback(() => {
    if (!work.thumb && !work.thumb_video) return null;

    return (
      <>
        {work.thumb && <Image src={work.thumb} alt="work" />}
        {work.thumb_video && (
          <Video visible={showVideo} ref={videoRef} muted loop preload="auto" playsInline>
            <source src={`${work.thumb_video}#t=0.1`} type="video/mp4" />
            Your browser does not support the video tag.
          </Video>
        )}
      </>
    );
  }, [showVideo, work.thumb, work.thumb_video]);

  useEffect(() => {
    const ref = itemRef.current;
    if (ref) {
      ref.addEventListener("mouseover", play);
      ref.addEventListener("mouseleave", pause);
    }

    return () => {
      if (ref) {
        ref.removeEventListener("mouseover", play);
        ref.removeEventListener("mouseleave", pause);
      }
    };
  }, []);

  return (
    <GridItem
      layout
      ref={itemRef}
      onClick={goToWork}
      animate={{ opacity: 1 }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
    >
      {renderThumb()}
      {work.nuevo && (
        <NewIconContainer>
          <NewIcon />
        </NewIconContainer>
      )}
      <WorkInfo>
        <div></div>
        <div>
          {work.tipo !== "case" && <WorkArtist>{work.artista}</WorkArtist>}
          <WorkName>{work.nombre}</WorkName>
        </div>
        <WorkType>
          {work.tipo}
          {work.compania ? (
            <>
              {" | "}
              <Color>{work.compania}</Color>
            </>
          ) : null}
        </WorkType>
      </WorkInfo>
    </GridItem>
  );
};

export default WorkThumb;
