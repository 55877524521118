import { useEffect, useState, useCallback, useRef, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ThemeContext } from "styled-components";
import { MenuOptionEnum } from "../../components/Navbar/types";
import { useResponsive } from "../../hooks/useResponsive";
import { Image } from "../../interfaces/images";
import { Work } from "../../interfaces/work";
import { actions } from "../../redux/loading";
import { RootState } from "../../redux/store";
import { getHome, getHomeWorks } from "../../services/api";
import { INITIAL_LOADING_TIME } from "../../utils/constants";
import { delayMs, onImagesLoaded } from "../../utils/utils";
import { BlockText, Home } from "../../interfaces/home";

export const useHome = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state: RootState) => state.loading);
  const { breakpoints } = useContext(ThemeContext);
  const firstRenderRef = useRef<boolean>(false);
  const scrollSectionRef = useRef<any>();
  const animationEndRef = useRef(true);
  const internalNavigationRef = useRef<boolean>(false);
  const { section = "" } = useParams<{ section: MenuOptionEnum }>();
  const [images, setImages] = useState<Image[]>([]);
  const [works, setWorks] = useState<Work[]>([]);
  const [weDoBlocks, setWeDoBlock] = useState<BlockText[]>([]);
  const [weAreBlocks, setWeAreBlock] = useState<BlockText[]>([]);
  const [actualOption, setActualOption] = useState(MenuOptionEnum.Carrousel);
  const [showMenu, toggleMenu] = useState(false);
  const [assets, setAssets] = useState<Home["assets"]>();
  const { isMobile } = useResponsive();
  // const [sectionsTop, setSectionsTop] = useState<number[]>([]);
  // const disableScroll = useRef(false);
  const navigate = useNavigate();

  const onOptionClick = (option: MenuOptionEnum) => {
    if (!scrollSectionRef.current || !animationEndRef.current) return;
    animationEndRef.current = false;
    setActualOption(option);
    toggleMenu(false);
    if (isMobile) {
      scrollToSectionMobile(option);
    } else {
      const section = Object.values(MenuOptionEnum).findIndex((o) => o === option);
      scrollSectionRef.current.scroll(section || 0);
    }
    internalNavigationRef.current = true;
    navigate(`/${option}`, { replace: true });
  };

  const scrollToSectionMobile = useCallback((option: MenuOptionEnum, initial: boolean = false) => {
    const start = initial ? document.getElementById("carrousel-section")?.getBoundingClientRect()?.top || 0 : 0;
    const id =
      option === MenuOptionEnum.Contact
        ? "#contact-section"
        : option === MenuOptionEnum.WeAre
        ? "#we-are-section"
        : option === MenuOptionEnum.WeDo
        ? "#we-do-section"
        : option === MenuOptionEnum.HomeWorks
        ? "#home-works-section"
        : "#carrousel-section";
    const container = document.querySelector(id);
    if (container) {
      const top = container.getBoundingClientRect()?.top || 0;
      const root = document.getElementById("root");
      if (root) {
        root.scroll({
          top: root.scrollTop + top - start - 60,
          left: 0,
          behavior: "smooth",
        });
      }
    }
    animationEndRef.current = true;
  }, []);

  const onSectionChanged = useCallback(
    (section: number) => {
      const options = Object.values(MenuOptionEnum);
      animationEndRef.current = false;
      setActualOption(options[section]);
      navigate(`/${options[section]}`, { replace: true });
    },
    [navigate]
  );

  const handleExplore = () => {
    animationEndRef.current = false;
    setActualOption(MenuOptionEnum.HomeWorks);
    toggleMenu(false);
    if (isMobile) {
      scrollToSectionMobile(MenuOptionEnum.HomeWorks);
    } else {
      scrollSectionRef.current.scroll(1);
    }
    internalNavigationRef.current = true;
    navigate(`/${MenuOptionEnum.HomeWorks}`, { replace: true });
 };

  const onAnimationEnd = useCallback(() => {
    animationEndRef.current = true;
  }, []);

  const onMenuClicked = useCallback(() => toggleMenu(true), [toggleMenu]);

  const onCloseMenu = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    toggleMenu(false);
  };

  const menuClickHandle = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      onMenuClicked();
    },
    [onMenuClicked]
  );

  const getImages = useCallback(async () => {
    try {
      const width = window.innerWidth;
      const { data } = await getHome();
      setImages(width < breakpoints.sm ? data?.imagenes_home_mobile || [] : data?.imagenes_home || []);
      setWeAreBlock(data?.we_are || []);
      setWeDoBlock(data?.we_do || []);
      setAssets(data?.assets || {})
    } catch (err) {
      console.log("Images err: ", err);
      throw err;
    }
  }, [breakpoints.sm]);

  const getWorks = useCallback(async () => {
    try {
      const { data } = await getHomeWorks();
      const width = window.innerWidth;
      const height = window.innerHeight;
      if (width < breakpoints.xs) {
        setWorks(data?.slice(0, 6) || []);
      } else if (width < breakpoints.sm) {
        setWorks(data?.slice(0, 7) || []);
      } else if (width < breakpoints.md) {
        setWorks(data?.slice(0, 8) || []);
      } else if (height < 720) {
        setWorks(data?.slice(0, 7) || []);
      } else {
        setWorks(data?.slice(0, 15) || []);
      }
    } catch (err) {
      console.log("Works err: ", err);
      throw err;
    }
  }, [breakpoints]);

  const initialRender = useCallback(async () => {
    try {
      await Promise.all([getImages(), getWorks()]);

      await delayMs(500);

      const carrousel = document.querySelector("#home-carrousel") as Element;
      if (carrousel) await onImagesLoaded(carrousel);

      await delayMs(1500);
    } catch (err) {
      console.log("Home error: ", err);
    }
  }, [getImages, getWorks]);

  const initialFetch = useCallback(async () => {
    await Promise.all([initialRender(), delayMs(INITIAL_LOADING_TIME)]);
    dispatch(actions.setLoading(false));
  }, [initialRender, dispatch]);

  useEffect(() => {
    if (!firstRenderRef.current) {
      initialFetch();
      firstRenderRef.current = true;
    }
  }, [initialFetch]);

  useEffect(() => {
    if (internalNavigationRef.current) {
      internalNavigationRef.current = false;
      return;
    }

    if (section) {
      const values = Object.values(MenuOptionEnum);
      const sectionNumber = values.findIndex((v) => v === section) || 0;
      if (!isNaN(sectionNumber) && sectionNumber > 0 && sectionNumber < values.length) {
        if (isMobile && !loading) {
          scrollToSectionMobile(section, true);
          scrollSectionRef.current.scroll(0, false);
        } else {
          setActualOption(section);
          scrollSectionRef.current.scroll(sectionNumber, false);
        }
      }
    }
  }, [isMobile, scrollToSectionMobile, section, loading]);

  // const handleScroll = (e: Event) => {
  //   const DELTA = 100;
  //   const { scrollTop } = e.target as HTMLElement;
  //   const options = Object.values(MenuOptionEnum);
  //   const currentIndex = options.findIndex((opt) => opt === section);
  //   const index = sectionsTop.findIndex((t, i, arr) => {
  //     if (scrollTop >= t && scrollTop < t + DELTA && i !== currentIndex) return true;
  //     return false;
  //   });
  //   if (index === -1) return;
  //   disableScroll.current = true;
  //   setActualOption(options[index]);
  //   navigate(`/${options[index]}`, { replace: true });
  // };

  // useEffect(() => {
  //   const root = document.getElementById("root");
  //   if (root) root.addEventListener("scroll", handleScroll);

  //   return () => {
  //     if (root) root.removeEventListener("scroll", handleScroll);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [sectionsTop, section]);

  // useEffect(() => {
  //   if (loading) return;

  //   const h = window.innerHeight - 100;
  //   const weDoSection = document.getElementById("we-do-section");
  //   const weAreSection = document.getElementById("we-are-section");

  //   setSectionsTop([
  //     0,
  //     h,
  //     h + (weDoSection?.clientHeight || h),
  //     h + (weDoSection?.clientHeight || h) + (weAreSection?.clientHeight || h),
  //   ]);
  // }, [loading]);

  return {
    isMobile,
    weDoBlocks,
    weAreBlocks,
    scrollSectionRef,
    loading,
    works,
    images,
    actualOption,
    showMenu,
    assets,
    onOptionClick,
    onSectionChanged,
    onAnimationEnd,
    onCloseMenu,
    menuClickHandle,
    handleExplore,
  };
};
