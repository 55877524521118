import styled, { css } from "styled-components";
import { VIDEO_ASPECT_RELATION } from "../../../../utils/constants";

export const BlockHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin: 100px 0 50px 0;
  gap: 26px;

  & > div {
    margin: 0;
  }

  ${({ theme: { spacing, breakpoints } }) => css`
    padding: 0px ${spacing.xl}px ;

    @media (max-width: ${breakpoints.lg}px) {
      padding: 0px ${spacing.lg}px;
    }

    @media (max-width: ${breakpoints.md}px) {
      padding: 0px ${spacing.md}px;
      grid-template-columns: 1fr;
      grid-row-gap: 24px;
      margin: 60px 0 32px 0;
    }

    @media (max-width: ${breakpoints.sm}px) {
      padding: 0px ${spacing.sm}px;
      margin: 42px 0 24px 0;
      grid-row-gap: 16px;
    }

    @media (max-width: ${breakpoints.xs}px) {
      padding: 0px ${spacing.xs}px;
    }
  `}
`;

export const Desciption = styled.div`
  width: 100%;
  column-count: 2;
  column-gap: 16px;
  font-family: "Open Sans";
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  color: #646464;

  

  ${({ theme: { breakpoints } }) => css`

    @media (max-width: ${breakpoints.lg}px) {
      font-size: 16px;
    }

    @media (max-width: ${breakpoints.sm}px) {
      column-count: 1;
      font-size: 14px;
    }
  `}
`;


export const VideoContainer = styled.div<{ width: number }>`
  width: 100%;
  margin-top: 50px;
  margin-bottom: 4px;

  & > p {
    display: none;
  }

  ${({ theme, width }) => css`
    & > iframe {
      width: ${width - 2 * theme.spacing.xl}px;
      height: ${(width - 2 * theme.spacing.xl) / VIDEO_ASPECT_RELATION}px;
    }

    @media (max-width: ${theme.breakpoints.lg}px) {
      padding: 0 ${theme.spacing.lg}px;
      & > iframe {
        width: ${width - 2 * theme.spacing.lg}px;
        height: ${(width - 2 * theme.spacing.lg) / VIDEO_ASPECT_RELATION}px;
      }
    }

    @media (max-width: ${theme.breakpoints.md}px) {
      padding: 0 ${theme.spacing.md}px;
      margin-top: 32px;
      & > iframe {
        width: ${width - 2 * theme.spacing.md}px;
        height: ${(width - 2 * theme.spacing.md) / VIDEO_ASPECT_RELATION}px;
      }
    }

    @media (max-width: ${theme.breakpoints.sm}px) {
      padding: 0 ${theme.spacing.sm}px;
      & > iframe {
        width: ${width - 2 * theme.spacing.sm}px;
        height: ${(width - 2 * theme.spacing.sm) / VIDEO_ASPECT_RELATION}px;
      }
    }

    @media (max-width: ${theme.breakpoints.xs}px) {
      padding: 0px;
      & > iframe {
        margin-left: 0px;
        width: ${width}px;
        height: ${width / VIDEO_ASPECT_RELATION}px;
      }
    }
  `}
`;

export const MosaicsContainer = styled.div`
  ${({ theme: { spacing, breakpoints } }) => css`
    padding: 0px ${spacing.xl}px ;

    @media (max-width: ${breakpoints.lg}px) {
      padding: 0px ${spacing.lg}px;
    }

    @media (max-width: ${breakpoints.md}px) {
      padding: 0px ${spacing.md}px;
      grid-template-columns: 1fr;
      grid-row-gap: 32px;
    }

    @media (max-width: ${breakpoints.sm}px) {
      padding: 0px ${spacing.sm}px;
    }

    @media (max-width: ${breakpoints.xs}px) {
      padding: 0px ${spacing.xs}px;
      /* padding: 0px; */
    }
  `}
`

export const FrontImage = styled.img`
  width: 100%;
`;