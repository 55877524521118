import { useCallback } from "react";
import { Work } from "../../interfaces/work";
import WorkThumb from "../WorkThumb/WorkThumb";
import { Grid, Title, Slash, TitleContainer, ButtonContainer } from "./styles";
import { OtherProjectsProps } from "./types";
import { useResponsive } from "../../hooks/useResponsive";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actions } from "../../redux/loading";
import { delayMs } from "../../utils/utils";
import { INITIAL_LOADING_TIME } from "../../utils/constants";
import { GoBackButton } from "../GoBack/GoBack";

const OtherProjects = ({ works }: OtherProjectsProps) => {
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBack = async () => {
    dispatch(actions.setLoading(true));
    await delayMs(INITIAL_LOADING_TIME + 100);
    navigate("/work", { replace: true });
  };

  const renderWorks = useCallback(() => {
    return works?.map((work: Work) => <WorkThumb key={`${work.tipo}-${work.id}`} work={work} />);
  }, [works]);
  return (
    <>
      {!isMobile && (
        <ButtonContainer>
          <GoBackButton onClick={handleBack}>GO BACK</GoBackButton>
        </ButtonContainer>
      )}
      <TitleContainer>
        <Slash />
        <Title>OTHER PROJECTS</Title>
      </TitleContainer>
      <Grid>{renderWorks()}</Grid>
    </>
  );
};

export default OtherProjects;
