
import Reveal from "../Reveal/Reveal";
import { Artist, Description, DetailContainer, Header, InfoContainer, WorkName, Label, Text, Row, Side } from "./styles";
import { WorkHeaderProps } from "./types";
import { useResponsive } from "../../hooks/useResponsive";
import { GoBackButton } from "../GoBack/GoBack";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actions } from "../../redux/loading";
import { delayMs } from "../../utils/utils";
import { INITIAL_LOADING_TIME } from "../../utils/constants";

const INITIAL_DELAY = 150;

export const WorkHeader = ({ artist, name, description, items, breakColumns = true }: WorkHeaderProps) => {
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleBack = async () => {
    dispatch(actions.setLoading(true));
    await delayMs(INITIAL_LOADING_TIME + 100);
    navigate("/work", { replace: true });
  };

  const texts = items.map((item) => (
    <div key={item.key}>
      <Label>{item.label}</Label>
      <Text>{item.value}</Text>
    </div>
  ));

  const leftTexts = texts.filter((_, i) => !(i % 2));
  const rightTexts = texts.filter((_, i) => i % 2);

  return (
    <Header>
      <Row marginBottom={!artist}>
        {artist ? (
          <Reveal delay={INITIAL_DELAY}>
            <Artist>{artist}</Artist>
          </Reveal>
        ) : (
          <div />
        )}
        {!isMobile && (
          <Reveal delay={INITIAL_DELAY}>
            <GoBackButton onClick={handleBack}>GO BACK</GoBackButton>
          </Reveal>
        )}
      </Row>

      {name && (
        <Reveal delay={INITIAL_DELAY + 250}>
          <WorkName>{name}</WorkName>
        </Reveal>
      )}
      <DetailContainer>
        <Reveal delay={INITIAL_DELAY + 500}>
          <InfoContainer breakColumns={breakColumns}>
            <Side>{leftTexts}</Side>
            <Side>{rightTexts}</Side>
          </InfoContainer>
        </Reveal>
        <Reveal delay={INITIAL_DELAY + 750}>
          <Description>{description}</Description>
        </Reveal>
      </DetailContainer>
    </Header>
  );
};
