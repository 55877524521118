import { useCallback } from "react";
import { MosaicsContainer, MosaicsColumn, MosaicImage } from "./styles";
import { MosaicsProps } from "./types";

const Mosaics = ({ id = "mosaic-container", images }: MosaicsProps) => {
  const renderColumn = useCallback(
    (columnIndex: number) => {
      return images
        .filter(img => img.imagen)
        .filter((img, index) => index % 2 === columnIndex)
        .map((image, index) => <MosaicImage key={`${columnIndex}-${index}`} loading="lazy" src={image.imagen} alt={image.descripcion} />);
    },
    [images]
  );

  return (
    <MosaicsContainer id={id}>
      <MosaicsColumn>{renderColumn(0)}</MosaicsColumn>
      <MosaicsColumn>{renderColumn(1)}</MosaicsColumn>
    </MosaicsContainer>
  );
};

export default Mosaics;
