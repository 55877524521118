import styled from "styled-components";

export const MosaicsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: calc(50% - 6px) calc(50% - 6px);
  grid-column-gap: 12px;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: 1fr;
    grid-row-gap: 12px;

    /* grid-template-columns: calc(50% - 3px) calc(50% - 3px);
    grid-column-gap: 6px; */
  }
`;

export const MosaicsColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: hidden;

  /* @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    gap: 6px;
  } */
`;

export const MosaicImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`