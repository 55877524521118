import axios from "axios";
import {
  CaseWorkType,
  Category,
  DesignWorkType,
  VideoWorkType,
  Work,
} from "../interfaces/work";
import { Home } from "../interfaces/home";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

export const getHome = () => {
  return client.get<Home>("/home-detail");
};

export const getHomeWorks = () => {
  return client.get<Work[]>("/home/works");
};

export const getWorks = (categories: string) => {
  return client.get<Work[]>("works/all", {
    params: {
      categories,
    },
  });
};

export const getCategories = () => {
  return client.get<Category[]>("categories");
};

export const getVideoWork = (path: string) => {
  return client.get<VideoWorkType>(`/works/videos/${path}`);
};

export const getDesignWork = (path: string) => {
  return client.get<DesignWorkType>(`/works/designs/${path}`);
};

export const getCaseWork = (path: string) => {
  return client.get<CaseWorkType>(`/works/cases/${path}`);
};
