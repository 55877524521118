import { createGlobalStyle } from "styled-components";
import { Theme } from "./types";

export const GlobalStyles = createGlobalStyle`
  body {
    overflow: hidden;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  *,
  ::before,
  ::after {
    box-sizing: border-box;
  }
`;

export const theme: Theme = {
  palette: {
    primary: "#f80b43",
    gray2D: "#2d2d2d",
    grayCC: "#cccccc",
    text: "#10081C",
  },
  breakpoints: {
    xs: 500,
    sm: 860,
    md: 1280,
    lg: 1600,
    xl: 1920
  },
  spacing: {
    xs: 32,
    sm: 64,
    md: 120,
    lg: 180,
    xl: 240,
  }
};