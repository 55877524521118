import { useCallback, useState } from "react";
import DragSlider from "../../../../components/DragSlider/DragSlider";
import Mosaics from "../../../../components/Mosaics/Mosaics";
import Reveal from "../../../../components/Reveal/Reveal";
import WorkSectionTitle from "../../../../components/WorkSectionTitle/WorkSectionTitle";
import {
  BlockHeader,
  Desciption,
  MosaicsContainer,
  VideoContainer,
  FrontImage,
} from "./styles";
import { BlockProps } from "./types";

const Block = ({ width, block }: BlockProps) => {
  const [galleryIndex, setGalleryIndex] = useState(0);
 

  const renderTitle = useCallback(() => {
    if (!block.titulo_en) return null;

    return (
      <Reveal>
        <BlockHeader>
          <WorkSectionTitle title={block.titulo_en} />
          <Desciption>{block.descripcion_en}</Desciption>
        </BlockHeader>
      </Reveal>
    );
  }, [block.descripcion_en, block.titulo_en]);

  const renderVideo = useCallback(() => {
    if (block?.video_principal) {
      return (
        <Reveal>
          <VideoContainer
            width={width}
            dangerouslySetInnerHTML={{
              __html: block?.video_principal || "",
            }}
          />
        </Reveal>
      );
    }
    return null;
  }, [block?.video_principal, width]);

  const renderImage = useCallback(() => {
    if (block?.imagen_principal) {
      return (
        <Reveal>
           <MosaicsContainer>
           <FrontImage src={block?.imagen_principal} alt={block?.titulo_en || ""} />
           </MosaicsContainer>
        </Reveal>
      );
    }
    return null;
  }, [block?.imagen_principal, block?.titulo_en]);

  const renderGallery = useCallback(() => {
    const pics = block?.galeria_pics?.filter((i) => i.imagen);
    if (pics && pics?.length > 0) {
      return (
        <Reveal>
          <DragSlider
            onSlideComplete={setGalleryIndex}
            activeIndex={galleryIndex}
            images={pics || []}
          />
        </Reveal>
      );
    }
    return null;
  }, [block?.galeria_pics, galleryIndex]);

  const renderMosaic = useCallback(() => {
    if (block?.mosaico && block?.mosaico?.length > 0) {
      return (
        <Reveal>
          <MosaicsContainer>
            <Mosaics images={block?.mosaico} />
          </MosaicsContainer>
        </Reveal>
      );
    }
    return null;
  }, [block?.mosaico]);

  return (
    <div>
      {renderTitle()}
      {renderVideo()}
      {renderImage()}
      {renderGallery()}
      {renderMosaic()}
    </div>
  );
};

export default Block;
