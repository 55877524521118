import { useMemo } from "react";
import { useDesignWork } from "./useDesignWork";
import { Container, VideoContainer, FrontImage, WorkContent, Desciption } from "./styles";
import { MenuOptionEnum } from "../../components/Navbar/types";
import { DesignWorkType } from "../../interfaces/work";
import { WorkHeader } from "../../components/WorkHeader/WorkHeader";
import { WorkItem } from "../../components/WorkHeader/types";
import OtherProjects from "../../components/OtherProjects/OtherProjects";
import DragSlider from "../../components/DragSlider/DragSlider";
import Mosaics from "../../components/Mosaics/Mosaics";
import { CustomNavbar } from "../../components/WorkNavbar/WorkNavbar";
import Reveal from "../../components/Reveal/Reveal";
import { useResponsive } from "../../hooks/useResponsive";
import WorkSectionTitle from "../../components/WorkSectionTitle/WorkSectionTitle";
import { Helmet } from "react-helmet";
import ShareButton from "../../components/ShareButton/ShareButton";

const items: WorkItem[] = [
  { key: "artista", label: "Artist" },
  { key: "año", label: "Year" },
  { key: "compania", label: "Company" },
];

export const DesignWork = () => {
  const { width, contentRef, containerRef, work, galeryIndex, setGaleryIndex, onOptionClick } = useDesignWork();
  const { isMobile } = useResponsive();

  const workItems = useMemo(() => {
    if (!work) return [];
    return items.map((item) => ({
      ...item,
      value: (work[item.key as keyof DesignWorkType] as string) || "",
    }));
  }, [work]);

  const title = `VRØDAS | ${work?.artista || ""} - ${work?.nombre || ""}`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={work?.descripcion_en || ""} />
        <meta name="thumbnail" content={work?.thumb} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="music video" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={work?.thumb} />
        <meta property="og:description" content={work?.descripcion_en || ""} />
        <meta property="og:site_name" content="VRØDAS" />
      </Helmet>
      <div className="design-work">
        <ShareButton title={title} text={work?.descripcion_en || ""} url={window.location.href} />
        <CustomNavbar animated={false} fixed actualOption={MenuOptionEnum.HomeWorks} onOptionClick={onOptionClick} />
        <Container ref={containerRef}>
          <WorkHeader
            artist={work?.artista}
            name={work?.nombre}
            description={work?.descripcion_en || ""}
            items={workItems}
          />
          <WorkContent ref={contentRef}>
            <Reveal delay={isMobile ? 0 : 400}>
              <FrontImage src={work?.cuerpo} alt={work?.nombre || ""} />
            </Reveal>
            <Reveal>
              <Mosaics id="design-mosaic" images={work?.mosaico || []} />
            </Reveal>
            <Reveal>
              <VideoContainer
                width={width}
                dangerouslySetInnerHTML={{
                  __html: work?.embed_code_video || "",
                }}
              />
            </Reveal>
          </WorkContent>
          {work?.titulo_2_en && (
            <Reveal>
              <WorkSectionTitle title={work?.titulo_2_en || ""} />
            </Reveal>
          )}
          <WorkContent>
            <Reveal>
              <Desciption>{work?.descripcion_2_en}</Desciption>
            </Reveal>
          </WorkContent>

          <Reveal>
            <DragSlider
              id="design-gallery"
              onSlideComplete={setGaleryIndex}
              activeIndex={galeryIndex}
              images={work?.galeria_pics?.filter((i) => i.imagen) || []}
            />
          </Reveal>
          <OtherProjects works={work?.otros_proyectos || []} />
        </Container>
      </div>
    </>
  );
};

export default DesignWork;
